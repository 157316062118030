.products3 {
  width: 100%;
  &_container {
    width: 100%;
    &_banner {
      width: 100%;
      height: 50vh;
      &_heading {
        width: 46%;
        justify-content: center;
        display: flex;
        align-items: flex-end;
        height: 45vh;
        h1 {
          font-size: 2vw;
          font-weight: 500;
          color: #fff;
        }
      }
    }
  }
  &_training {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #fff;
    margin-top: 3vw;
    &_container {
      display: flex;
      justify-content: center;
      width: 85%;
    }
    &_section {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      width: 100%;
      &_header {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 2vw;
        h2 {
          font-size: 2vw;
          font-weight: 600;
          color: #07131b;
          transform-origin: center;
          animation: stretchAnimation 1s ease-in-out alternate;
          
        }
        &_image {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          img {
            width: 7vw;
          }
        }
      }
      &_content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        &_left {
          width: 48%;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          &_header {
            margin-bottom: 1vw;
            h3 {
              font-size: 1.6vw;
              font-weight: 800;
              color: #07131b;
              line-height: 2.5vw;
            }
          }
          &_data {
            p {
              font-size: 1.3vw;
              font-weight: 500;
              color: #545252;
              line-height: 2.2vw;
              text-align: justify;
            }
          }
        }
        &_right {
          width: 45%;
          &_image {
            width: 100%;
            img {
              width: 100%;
            }
          }
        }
      }
    }
  }
  &_features {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #f0f0f0;
    margin-top: 3vw;
    &_container {
      display: flex;
      justify-content: center;
      width: 85%;
    }
    &_section {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      width: 100%;
      &_header {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 3vw;
        h2 {
          font-size: 2vw;
          font-weight: 600;
          color: #07131b;
          transform-origin: center;
          animation: stretchAnimation 1s ease-in-out alternate;
         
        }
        &_image {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          img {
            width: 6vw;
          }
        }
      }
      &_content {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        flex-wrap: wrap;
        &_cards {
          margin: 1vw;
          padding: 1vw;
          width: 20%;
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          &_icon {
            img {
              width: 5vw;
            }
          }
          &_text {
            margin: 0.5vw 0 1vw 0;
            height: 3vw;
            h3 {
              color: #000;
              font-size: 1.3vw;
              font-weight: 700;
            }
          }
          &_content {
            p {
              color: rgba(0, 0, 0, 0.7);
              font-size: 1vw;
              font-weight: 500;
              line-height: 1.5vw;
            }
          }
        }
        .line {
          border-right: 1.7px solid darkgrey;
          height: 26vh;
          align-self: center;
        }
        &_down {
          display: flex;
          width: 80%;
        }
      }
    }
  }
  &_document {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #fff;
    position: relative;
    .orange{
      position: absolute;
      right: 0;
      top: 0;
      width: 8%;
      img{
        width: 100%;
      }
    }
    .blue{
      position: absolute;
      width: 8%;
      bottom: 0;
      left: 0;
      img{
        width: 100%;
      }
    }
    &_container{
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 3vw;
    }
    &_section {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      width: 80%;
      &_header {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 3vw;
        margin-top: 3vw;
        h2 {
          font-size: 1.7vw;
          font-weight: 600;
          line-height: 2vw;
          transform-origin: center;
          animation: stretchAnimation 1s ease-in-out alternate;
          
        }
        &_image {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          img {
            width: 6vw;
          }
        }
      }
      &_content {
        display: flex;
        margin-bottom: 1vw;
        justify-content: space-between;
        &_class {
          border-radius: 12px;
          background: #fff;
          box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
          padding: 1vw;
          width: 29%;
          height: max-content;
          &_icon {
            img {
              width: 3vw;
              margin-top: 0.1vw;
              padding: 0 1vw;
            }
          }
          &_text {
            padding: 1vw 0 2vw 1vw;
            &_head {
              h3 {
                color: #000;
                font-size: 1.1vw;
                font-weight: 700;
                line-height: 20px;
              }
            }
            &_content {
              padding: 0 1vw 0 0;
              p {
                color: #585555;
                font-size: 1vw;
                font-weight: 500;
                line-height: 1.5vw;
                margin-bottom: 1vw;
                margin-top: 0.5vw;
                letter-spacing: 0.13px;
                text-align: justify;
              }
            }
          }
        }
        &_class:nth-child(2n) {
          margin-top: 4vw;
        }
        &_class:nth-child(3n) {
          margin-top: 8vw;
        }
      }
    }
  }
  &_bottom {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f6f6f6;
    padding-bottom: 2vw;
    &_container {
      width: 85%;
      display: flex;
      justify-content: space-between;
      padding-top: 3vw;
      &_left {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        &_text {
          row-gap: 2vw;
          flex-direction: column;
          display: flex;
          p {
            color: #413f3f;
            font-size: 1.4vw;
            font-weight: 600;
            line-height: 1.8vw;
            text-align: justify;
          }
          button{
          background: linear-gradient(180deg, #ebc608 0%, #f88e25 91.2%);
          border: none;
          color: #fff;
          padding: 1vw 1.5vw;
          font-size: 1vw;
          width: 11vw;
          border-radius: 7px;
          cursor: pointer;
          }
        }
      }
      &_right {
        width: 45%;
        &_image{
          img {
            width: 100%;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .products3 {
    width: 100%;
    &_container {
      width: 100%;
      &_banner {
        width: 100%;
        height: 30vh;
        &_heading {
          width: 88%;
          height: 25vh;
          h1 {
            font-size: 5vw;
          }
        }
      }
    }
    &_training {
      &_container{
        width: 90%;
      }
      &_section {
        &_header {
          h2 {
            font-size: 5vw;
          }
          &_image {
            img {
              width: 14vw;
            }
          }
        }
        &_content {
          display: flex;
          flex-direction: column;
          width: 100%;
          &_left {
            width: 100%;
            &_header {
              margin-bottom: 2vw;
              h3 {
                font-size: 4vw;
                line-height: 5vw;
              }
            }
            &_data {
              p {
                font-size: 3.5vw;
                line-height: 4.5vw;
                margin-bottom: 2vw;
                text-align: justify;
              }
            }
          }
          &_right {
            width: 100%;
            &_image {
              width: 100%;
              img {
                width: 100%;
              }
            }
          }
        }
      }
    }
    &_features {
      width: 100%;
      &_container {
        width: 100%;
      }
      &_section {
        &_header {
          h2 {
            font-size: 5vw;
          }
          &_image {
            width: 100%;
            img {
              width: 15vw;
            }
          }
        }
        &_content {
          justify-content: space-around;
          align-items: flex-start;
          width: 100%;
          flex-wrap: wrap;
          padding-bottom: 3vw;
          &_cards {
            width: 100%;
            &_icon {
              img {
                width: 8vw;
              }
            }
            &_text {
              margin: 1vw 0 1vw 0;
              height: auto;
              h3 {
                font-size: 4vw;
              }
            }
            &_content {
              width: 90%;
              p {
                // text-align: justify;
                font-size: 3vw;
                line-height: 4vw;
              }
            }
          }
          .line {
            display: none;
          }
          &_down {
            display: flex;
            width: 80%;
          }
        }
      }
    }
    &_document {
      display: flex;
      justify-content: center;
      width: 100%;
      background-color: #fff;
      margin-top: 6vw;
      margin-bottom: 3vw;
      .orange{
        position: absolute;
        right: 0;
        top: -6vw;
        width: 10%;
        img{
          width: 100%;
        }
      }
      .blue{
        position: absolute;
        width: 10%;
        bottom: -5vw;
        left: 0;
        img{
          width: 100%;
        }
      }
      &_container {
        display: flex;
        justify-content: center;
        width: 85%;
      }
      &_section {
        &_header {
          h2 {
            font-size: 4vw;
            line-height: 5vw;
          }
          &_image {
            img {
              width: 12vw;
            }
          }
        }
        &_content {
          display: flex;
          margin-bottom: 1vw;
          flex-direction: column;
          &_class {
            border-radius: 5px;
            padding: 1vw;
            width: 100%;
            height: max-content;
            display: flex;
            flex-direction: column;
            align-items: center;
            &_icon {
              img {
                width: 10vw;
                margin-top: 0.1vw;
                padding: 1vw;
              }
            }
            &_text {
              padding: 0 1vw;
              &_head {
                h3 {
                  color: #000;
                  font-size: 4vw;
                  font-weight: 700;
                  line-height: 5vw;
                  text-align: center;
                }
              }
              &_content {
                padding: 1vw;
                p {
                  color: #585555;
                  font-size: 3vw;
                  font-weight: 500;
                  line-height: 4vw;
                  margin-bottom: 1vw;
                  margin-top: 0.5vw;
                  width: 100%;
                  letter-spacing: 0.13px;
                  text-align: center;
                }
              }
            }
          }
          &_class:nth-child(2n) {
            margin-top: 3vw;
          }
          &_class:nth-child(3n) {
            margin-top: 3vw;
          }
        }
      }
    }
    &_bottom {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #f6f6f6;
      padding-bottom: 4vw;
      &_container {
        width: 90%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding-top: 3vw;
        &_left {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          &_text {
            flex-direction: column;
            display: flex;
            p {
              color: #413f3f;
              font-size: 3vw;
              font-weight: 600;
              line-height: 4vw;
              text-align: justify;
            }
            button{
              padding: 1vw 2vw;
              font-size: 2.5vw;
              width: 20vw;
              border-radius: 1vw;
            }
          }
        }
        &_right {
          width: 100%;
          display: flex;
          justify-content: center;
          margin-top: 3vw;
          &_image{
            display: flex;
            justify-content: center;
            img {
              width: 100%;
            }
          }
        
        }
      }
    }
  }
}
