@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.contact {
  width: 100%;
  &_banner {
    width: 100%;
    height: 50vh;
    &_heading {
      width: 26%;
      justify-content: center;
      display: flex;
      align-items: flex-end;
      height: 45vh;
      h1 {
        font-size: 2vw;
        font-weight: 500;
        color: #fff;
      }
    }
  }
  &_container {
    width: 100%;
    &_location {
      display: flex;
      justify-content: center;
      width: 100%;
      background-color: #fff;
      margin-top: 6vw;
      &_details {
        display: flex;
        justify-content: center;
        width: 85%;
        &_section {
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          width: 100%;
          &_heading {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin-bottom: 4vw;
            h2 {
              font-size: 2vw;
              font-weight: 600;
              color: #07131b;
            }
            &_line {
              width: 2%;
              img {
                width: 9vw;
                margin-bottom: 2vw;
              }
            }
          }
        }
        &_content {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 100%;
          &_left {
            width: 50%;
            display: flex;
            flex-direction: column;
            &_container {
              display: flex;
              justify-content: center;
              margin-bottom: 5vw;
              &_image {
                img {
                  width: 3vw;
                  margin-right: 1vw;
                }
              }
              &_data {
                width: 63%;
                h3 {
                  font-size: 1.6vw;
                  font-weight: 800;
                  color: #07131b;
                  line-height: 2.5vw;
                }
                p {
                  font-size: 1.3vw;
                  font-weight: 500;
                  color: #545252;
                  line-height: 2.5vw;
                }
              }
            }
          }
          &_right {
            width: 50%;
            display: flex;
            flex-direction: column;
            &_container {
              display: flex;
              justify-content: center;
              margin-bottom: 10vw;
              &_image {
                img {
                  width: 3vw;
                  margin-right: 1vw;
                }
              }
              &_data {
                width: 56%;
                h3 {
                  font-size: 1.6vw;
                  font-weight: 800;
                  color: #07131b;
                  line-height: 2.5vw;
                }
                p {
                  font-size: 1.3vw;
                  font-weight: 500;
                  color: #545252;
                  line-height: 2.5vw;
                }
              }
            }
          }
        }
      }
    }
  }
  &_box {
    width: 100%;
    background-color: #f2f2f2;
    &_heading {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      h2 {
        margin-top: 3vw;
        font-size: 2vw;
        font-weight: 600;
        color: #07131b;
        transform-origin: center;
        animation: stretchAnimation 1s ease-in-out alternate;
      }
      &_image {
        width: 9%;
        img {
          width: 6vw;
          margin-bottom: 3vw;
        }
      }
    }
    &_details {
      display: flex;
      justify-content: center;
      &_container {
        width: 70%;
        &_content {
          display: flex;
          justify-content: space-between;
          margin-bottom: 1vw;
          &_topinputs {
            width: 32%;
            display: flex;
            flex-direction: column;
            input {
              border: none;
              font-size: 1vw;
              width: 90%;
              color: #575151;
              font-weight: 600;
              border-radius: 0.5vw;
              padding: 1vw;
            }
            input[type="number"]::-webkit-inner-spin-button,
            input[type="number"]::-webkit-outer-spin-button {
              -webkit-appearance: none;
            }
            span {
              font-size: 1vw;
              color: red;
            }
          }
        }
        &_content2 {
          display: flex;
          justify-content: space-between;
          margin-bottom: 1vw;
          input {
            border: none;
            font-size: 1vw;
            width: 63%;
            color: #575151;
            font-weight: 600;
            border-radius: 0.5vw;
            padding: 1vw;
          }
          select {
            border: none;
            font-size: 1vw;
            width: 32%;
            color: #656565;
            font-weight: 600;
            border-radius: 0.5vw;
            padding: 1vw 1vw 1vw 0.5vw;
          }
        }
        &_content3 {
          display: flex;
          justify-content: space-around;
          margin-bottom: 1vw;
          input {
            border: none;
            font-size: 1vw;
            width: 100%;
            color: #575151;
            font-weight: 600;
            border-radius: 0.5vw;
            padding: 1vw;
          }
        }
        &_comment {
          display: flex;
          justify-content: space-around;
          margin-top: 1vw;
          textarea {
            width: 100%;
            font-size: 1vw;
            height: 30vh;
            border: none;
            color: #575151;
            font-weight: 600;
            border-radius: 0.5vw;
            padding: 1vw;
            font-family: "Outfit";
          }
        }
        &_check {
          display: flex;
          align-items: baseline;
          padding: 1vw 0;
          gap: 0.6vw;
          &_content {
            display: block;
            p {
              font-size: 1vw;
              color: #575151;
            }
          }
        }
        &_button {
          margin: 1.5vw 0 4vw 0;
          display: flex;
          justify-content: flex-end;
          button {
            background: linear-gradient(180deg, #ebc608 0%, #f88e25 91.2%);
            width: 10vw;
            padding: 1vw 0;
            font-size: 1.2vw;
            border-radius: 0.5vw;
            font-weight: 500;
            border: none;
            cursor: pointer;
            color: #fff;
          }
        }
      }
    }
  }
  .popup {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100vh;
    @include flexCenter;
    background: #00000062;
    z-index: 9999;
    &_container {
      background-color: #fff;
      border: 1px solid #ccc;
      padding: 1vw;
      width: 25%;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
      border-radius: 10px;
      p {
        font-size: 1.2vw;
        font-weight: 500;
        padding: 1vw;
      }
    }

    &_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #ccc;
      padding-bottom: 1vw;
      margin-bottom: 10px;
      h2 {
        margin: 0;
        font-size: 1.5vw;
        font-weight: 700;
      }
      .fa-times {
        cursor: pointer;
        font-weight: 300;
        font-size: 1.4vw;
      }
    }
  }

  .loaderWrapper {
    @include flexCenter;
    background: #00000062;
    overflow: hidden;
    z-index: 100;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100vh;

    &__loader {
      @include absoluteCenter;
      border: 4px solid #f0f0f0;
      border-radius: 50%;
      border-top: 4px solid #212121;
      width: $font_top9;
      height: $font_top9;
      -webkit-animation: spin 2s linear ease-in-out;
      animation: spin 2s linear ease-in-out;
    }

    &__smallLoader {
      border: 2px solid #f0f0f0;
      border-top: 2px solid #212121;
      width: $font_large;
      height: $font_large;
    }
    &__tableLoader {
      border: 2px solid #f0f0f0;
      border-top: 2px solid #212121;
      width: $font_large;
      height: $font_large;
    }

    @-webkit-keyframes spin {
      0% {
        -webkit-transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
      }
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .contact {
    &_banner {
      width: 100%;
      height: 30vh;
      &_heading {
        height: 25vh;
        width: 35%;
        h1 {
          font-size: 5vw;
        }
      }
    }
    &_container {
      &_location {
        &_details {
          &_content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;
            &_left {
              width: 100%;
              display: flex;
              flex-direction: column;
              &_container {
                display: flex;
                justify-content: center;
                margin-bottom: 9vw;
                &_image {
                  img {
                    width: 8vw;
                    margin-right: 3vw;
                  }
                }
                &_data {
                  width: 100%;
                  h3 {
                    font-size: 4vw;
                    line-height: 5vw;
                  }
                  p {
                    font-size: 3vw;
                    line-height: 4vw;
                  }
                }
              }
            }
            &_right {
              width: 100%;
              display: flex;
              flex-direction: column;
              &_container {
                display: flex;
                justify-content: center;
                margin-bottom: 9vw;
                &_image {
                  img {
                    width: 8vw;
                    margin-right: 3vw;
                  }
                }
                &_data {
                  width: 100%;
                  h3 {
                    font-size: 4vw;
                    line-height: 5vw;
                  }
                  p {
                    font-size: 3vw;
                    line-height: 4vw;
                  }
                }
              }
            }
          }
        }
      }
    }
    &_box {
      &_heading {
        h2 {
          margin-top: 3vw;
          font-size: 5vw;
        }
        &_image {
          width: 30%;
          display: flex;
          justify-content: center;
          img {
            width: 20vw;
            margin-bottom: 3vw;
          }
        }
      }
      &_details {
        display: flex;
        justify-content: center;
        &_container {
          width: 90%;
          &_content {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            margin-bottom: 1vw;
            &_topinputs {
              width: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              input {
                border: none;
                font-size: 3vw;
                width: 90%;
                border-radius: 7px;
                padding: 2vw;
                margin: 2vw 0;
              }
              span {
                font-size: 2.5vw;
              }
            }
          }

          &_content2 {
            display: flex;
            flex-direction: column;
            align-items: center;
            input {
              border: none;
              font-size: 3vw;
              width: 90%;
              border-radius: 7px;
              padding: 2vw;
              margin: 2vw 0;
            }
            select {
              border: none;
              font-size: 3vw;
              width: 95%;
              border-radius: 7px;
              padding: 2vw;
              margin: 2vw 0;
            }
          }
          &_content3 {
            display: flex;
            justify-content: space-around;
            margin-bottom: 1vw;
            input {
              border: none;
              font-size: 3vw;
              width: 90%;
              border-radius: 7px;
              padding: 2vw;
              margin: 2vw 0;
            }
          }
          &_comment {
            display: flex;
            justify-content: space-around;
            margin-top: 2vw;
            textarea {
              width: 90%;
              font-size: 3vw;
              height: 20vh;
              border-radius: 7px;
              padding: 2vw;
            }
          }
          &_button {
            margin: 1.5vw 0 4vw 2.5vw;
            button {
              width: 15vw;
              padding: 1vw 2vw;
              font-size: 3vw;
              border-radius: 5px;
            }
          }
        }
      }
    }
    .popup {
      position: fixed;
      top: 0;
      width: 100%;
      height: 100vh;
      @include flexCenter;
      background: #00000062;
      z-index: 9999;
      &_container {
        background-color: #fff;
        border: 1px solid #ccc;
        padding: 3vw;
        width: 50%;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
        border-radius: 10px;
        p {
          font-size: 3vw;
          font-weight: 500;
          padding: 1vw;
        }
      }

      &_header {
        h2 {
          font-size: 4vw;
        }
        .fa-times {
          font-weight: 300;
          font-size: 4vw;
        }
      }
    }
  }
}
