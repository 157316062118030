@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.pt4 {
  &_container {
    width: 100%;
    &_banner {
      width: 100%;
      height: 50vh;
      &_heading {
        width: 24%;
        justify-content: center;
        display: flex;
        align-items: flex-end;
        height: 45vh;
        h1 {
          font-size: 2vw;
          font-weight: 500;
          color: #fff;
        }
      }
    }
  }
  &_pharma {
    @include flexJCenter;
    width: 100%;
    background-color: #fff;
    margin-top: 3vw;
    margin-bottom: 3vw;
    &_container {
      @include flexJCenter;
      width: 85%;
    }
    &_section {
      @include flexColCenter;
      width: 100%;
      &_header {
        @include flexColCenter;
        margin-bottom: 1vw;
        width: 67%;
        h3 {
          font-size: 2vw;
          font-weight: 700;
          color: #07131b;
          transform-origin: center;
          animation: stretchAnimation 1s ease-in-out alternate;
          
        }
      }
      &_line {
        display: flex;
        justify-content: flex-end;
        width: 15%;
        img {
          width: 8vw;
        }
      }
      &_content {
        @include flexrowbetween;
        width: 100%;
        &_left {
          width: 45%;
          img {
            width: 100%;
          }
        }
        &_right {
          width: 50%;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          &_heading {
            margin-bottom: 1vw;
            h3 {
              font-size: 1.5vw;
              font-weight: 600;
              color: #000000;
              line-height: 2.2vw;
            }
          }
          &_data {
            margin-bottom: 2vw;
            p {
              font-size: 1.3vw;
              font-weight: 500;
              color: #545252;
              line-height: 2vw;
              text-align: justify;
            }
          }
        }
      }
    }
  }
  &_features {
    display: flex;
    justify-content: center;
    align-items: center;
    &_container {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-color: #f0f0f0;
      padding: 3vw 0;
      &_heading {
        @include flexColCenter;
        margin-bottom: 2vw;
        h3 {
          font-size: 2vw;
          font-weight: 600;
          color: #07131b;
          transform-origin: center;
          animation: stretchAnimation 1s ease-in-out alternate;
         
        }
        &_line {
          display: flex;
          justify-content: flex-end;
          width: 95%;
          img {
            width: 7vw;
          }
        }
      }
      &_section {
        display: flex;
        width: 85%;
        justify-content: space-between;
        &_card {
          width: 30%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background-color: #ffffff;
          border-radius: 0.8vw;
          padding: 3vw 0;
          cursor: pointer;
          &:nth-child(1):hover {
            background-image: url(../../assets/images/KeyFeature1.png);
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            h3 {
              color: #fff;
            }
            .image1 {
              display: none;
            }
            .image2 {
              display: block;
            }
          }

          &:nth-child(2):hover {
            background-image: url(../../assets/images/KeyFeature2.png);
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            h3 {
              color: #fff;
            }
            .image1 {
              display: none;
            }
            .image2 {
              display: block;
            }
          }

          &:nth-child(3):hover {
            background-image: url(../../assets/images/KeyFeature3.png);
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            h3 {
              color: #fff;
            }
            .image1 {
              display: none;
            }
            .image2 {
              display: block;
            }
          }
          &_top {
            margin-bottom: 4vw;
            &_image {
              img {
                width: 5vw;
              }
              .image1 {
                display: block;
              }
              .image2 {
                display: none;
              }
            }
          }
          &_center {
            margin-bottom: 5vw;
            h1 {
              color: #f88e25;
              font-size: 3.5vw;
              font-weight: 500;
              line-height: 4vw;
              text-align: center;
            }
            &_line {
              width: 6vw;
              height: 3px;
              flex-shrink: 0;
              border-radius: 10px;
              background: var(
                --blue,
                linear-gradient(180deg, #0cb1d6 2.5%, #07426a 100%)
              );
            }
          }
          &_bottom {
            &_text {
              h3 {
                color: #000;
                font-size: 1.2vw;
                font-weight: 700;
                line-height: 2vw;
              }
            }
          }
        }
      }
    }
  }
  &_document {
    width: 100%;
    background-color: #ffffff;
    &_container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 3vw 0;
    }
    &_heading {
      width: 100%;
      margin-bottom: 2vw;
      h2 {
        color: #07131b;
        text-align: center;
        font-size: 2vw;
        font-weight: 600;
        line-height: 3vw;
        transform-origin: center;
        animation: stretchAnimation 1s ease-in-out alternate;
       
      }
      &_line {
        width: 60%;
        display: flex;
        justify-content: flex-end;
        img {
          width: 8vw;
        }
      }
      &_text {
        margin-bottom: 3vw;
        width: 72%;
        p {
          color: #000;
          text-align: center;
          font-size: 1.3vw;
          font-weight: 500;
          line-height: 2vw;
        }
      }
    }
    &_section {
      width: 90%;
      &_content {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        &_cards {
          width: 17%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          &_icon {
            margin-bottom: 1vw;
            img {
              width: 5vw;
            }
          }
          &_text {
            padding-bottom: 1vw;
            display: flex;
            justify-content: center;
            align-items: center;
            // height: 3vw;
            h3 {
              text-align: center;
              color: #000;
              font-size: 1vw;
              font-weight: 700;
            }
          }
          &_data {
            p {
              color: rgba(0, 0, 0, 0.7);
              text-align: center;
              font-size: 1vw;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
  &_cloud {
    width: 100%;
    background-color: #f0f0f0;
    &_container {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 3vw 0;
    }
    &_section {
      width: 80%;
      display: flex;
      justify-content: space-between;
      &_left {
        width: 42%;
        display: flex;
        align-items: center;
        justify-content: center;
        &_data {
          h3 {
            color: #07131b;
            font-size: 2vw;
            font-weight: 700;
            line-height: 3vw;
            margin-bottom: 1vw;
          }
          p {
            color: #000;
            font-size: 1.2vw;
            font-weight: 500;
            line-height: 2vw;
            margin-bottom: 1.5vw;
          }
          img{
            width: 90%;
          }
        }
      }
      &_right {
        width: 50%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &_cards1 {
          width: 48%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          gap: 1vw;
          &_cardtop {
            width: 80%;
            border-radius: 1vw;
            background: #fff;
            padding: 1.5vw 2vw 4vw 2vw;
            &:hover{
            box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.22);
            }
            &_icon {
              margin-bottom: 1vw;
              img {
                width: 3vw;
              }
            }
            h4 {
              color: #000;
              font-size: 1vw;
              font-weight: 700;
              line-height: 2vw;
              margin-bottom: 0.5vw;
            }
            p {
              color: rgba(0, 0, 0, 0.6);
              font-size: 0.9vw;
              font-weight: 500;
            }
          }
          &_cardbottom {
            width: 78%;
            border-radius: 1vw;
            background: #fff;
            padding: 1.5vw 2vw 2vw 2vw;
            &:hover{
              box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.22);
              }
            &_icon {
              margin-bottom: 1vw;
              img {
                width: 3vw;
              }
            }
            h4 {
              color: #000;
              font-size: 1vw;
              font-weight: 700;
              line-height: 2vw;
              margin-bottom: 0.5vw;
            }
            p {
              color: rgba(0, 0, 0, 0.6);
              font-size: 0.9vw;
              font-weight: 500;
            }
          }
        }
        &_cards2 {
          width: 49%;
          &_card {
            width: 78%;
            border-radius: 1vw;
            background: #fff;
            padding: 1.5vw 2vw 3vw 2vw;
            &:hover{
              box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.22);
              }
            &_icon {
              margin-bottom: 1vw;
              img {
                width: 3vw;
              }
            }
            h4 {
              color: #000;
              font-size: 1vw;
              font-weight: 700;
              line-height: 2vw;
              margin-bottom: 0.5vw;
            }
            p {
              color: rgba(0, 0, 0, 0.6);
              font-size: 0.9vw;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
  &_bottom {
    &_image {
      padding: 4vw 0;
      background-image: url(../../assets/images/bottomp4.jpg);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      display: flex;
      justify-content: center;
      &_text {
        width: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        h3 {
          color: #fff;
          font-size: 2vw;
          font-weight: 600;
          line-height: 2vw;
          margin-bottom: 2vw;
        }
        p {
          color: #efefef;
          text-align: center;
          font-size: 1.5vw;
          font-weight: 500;
          line-height: 30px;
        }
      }
    }
  }
  &_request {
    &_container {
      width: 100%;
      display: flex;
      justify-content: center;
      background-color: #fff;
    }
    &_section {
      width: 85%;
      border-radius: 0.8vw;
      background: #dae4fd;
      display: flex;
      justify-content: space-between;
      margin: 2vw 0;
      padding: 3vw 3vw 2vw 3vw;
      &_left {
        width: 55%;
        display: flex;
        flex-direction: column;
        h3 {
          color: #000;
          font-size: 1.5vw;
          font-weight: 700;
          line-height: 2.5vw;
          margin-bottom: 1vw;
        }
        p {
          color: #000;
          font-size: 1.2vw;
          font-weight: 500;
          line-height: 2vw;
          margin-bottom: 2vw;
        }
        button {
          width: 12vw;
          font-size: 1.1vw;
          font-weight: 600;
          background: linear-gradient(180deg, #ebc608 0%, #f88e25 91.2%);
          color: #fff;
          padding: 0.8vw;
          border: none;
          border-radius: 7px;
          cursor: pointer;
        }
      }
      &_right {
        width: 40%;
        img {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .pt4 {
    &_container {
      &_banner {
        height: 30vh;
        &_heading {
          width: 30%;
          height: 25vh;
          h1 {
            font-size: 5vw;
          }
        }
      }
    }
    &_pharma {
      @include flexJCenter;
      margin-top: 6vw;
      &_container {
        width: 90%;
      }
      &_section {
        width: 100%;
        align-items: flex-start;
        &_header {
          margin-bottom: 5vw;
          width: 90%;
          h3 {
            font-size: 4vw;
            font-weight: 700;
            color: #07131b;
            text-align: justify;
          }
        }
        &_line {
          display: flex;
          justify-content: flex-end;
          width: 20%;

          img {
            width: 10vw;
          }
        }
        &_content {
          flex-direction: column-reverse;
          width: 100%;
          &_left {
            width: 100%;
            img {
              width: 100%;
            }
          }
          &_right {
            width: 100%;
            &_heading {
              margin-bottom: 2vw;
              h3 {
                font-size: 4vw;
                line-height: 5vw;
                font-weight: 600;
              }
            }
            &_data {
              margin-bottom: 4vw;
              p {
                font-size: 3vw;
                line-height: 4vw;
                text-align: justify;
              }
            }
          }
        }
      }
    }
    &_features {
      margin-top: 2vw;
      &_container {
        padding: 4vw 0;
        &_heading {
          margin-bottom: 4vw;
          h3 {
            font-size: 4vw;
          }
          &_line {
            display: flex;
            justify-content: flex-end;
            width: 95%;
            img {
              width: 12vw;
            }
          }
        }
        &_section {
          display: flex;
          width: 85%;
          flex-direction: column;
          align-items: center;
          &_card {
            width: 80%;
            border-radius: 1vw;
            padding: 5vw 0;
            margin-bottom: 2vw;
            &_top {
              margin-bottom: 4vw;
              &_image {
                img {
                  width: 10vw;
                }
              }
            }
            &_center {
              margin-bottom: 5vw;
              h1 {
                font-size: 8vw;
                line-height: 9vw;
              }
              &_line {
                width: 10vw;
                height: 0.5vw;
              }
            }
            &_bottom {
              &_text {
                h3 {
                  font-size: 4vw;
                  line-height: 5vw;
                }
              }
            }
          }
        }
      }
    }
    &_document {
      width: 100%;
      &_container {
        padding: 6vw 0;
      }
      &_heading {
        width: 100%;
        margin-bottom: 4vw;
        h2 {
          font-size: 5vw;
          line-height: 6vw;
        }
        &_line {
          width: 75%;
          img {
            width: 15vw;
          }
        }
        &_text {
          margin-bottom: 3vw;
          width: 80%;
          p {
            font-size: 3.5vw;
            line-height: 5vw;
            text-align: justify;
          }
        }
      }
      &_section {
        width: 90%;
        &_content {
          display: flex;
          flex-direction: column;
          &_cards {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            &_icon {
              margin-bottom: 1vw;
              img {
                width: 10vw;
              }
            }
            &_text {
              padding-bottom: 1vw;
              display: flex;
              justify-content: center;
              align-items: center;
              // height: 3vw;
              h3 {
                font-size: 4vw;
              }
            }
            &_data {
              p {
                font-size: 3vw;
                // text-align: justify;
              }
            }
          }
        }
      }
    }
    &_cloud {
      &_container {
        width: 100%;
        padding: 2vw 0;
      }
      &_section {
        width: 90%;
        display: flex;
        flex-direction: column;
        &_left {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          &_data {
            margin: 3vw 0;
            width: 100%;
            h3 {
              font-size: 5vw;
              line-height: 6vw;
              margin-bottom: 1vw;
              text-align: center;
              margin-bottom: 3vw;
            }
            p {
              font-size: 3vw;
              line-height: 4vw;
              text-align: center;
              margin-bottom: 3vw;
            }
            img{
              width: 100%;
            }
          }
        }
        &_right {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          &_cards1 {
            width: 100%;
            display: flex;
            align-items: center;
            &_cardtop {
              width: 80%;
              padding: 3vw 2vw 10vw 2vw;
              display: flex;
              align-items: center;
              flex-direction: column;
              &_icon {
                margin-bottom: 1vw;
                img {
                  width: 10vw;
                }
              }
              h4 {
                font-size: 4vw;
                line-height: 5vw;
                margin-bottom: 1vw;
              }
              p {
                font-size: 3vw;
              }
            }
            &_cardbottom {
              width: 80%;
              padding: 3vw 2vw;
              display: flex;
              align-items: center;
              flex-direction: column;
              margin-top: 1vw;
              &_icon {
                margin-bottom: 1vw;
                img {
                  width: 10vw;
                }
              }
              h4 {
                font-size: 4vw;
                line-height: 5vw;
                margin-bottom: 1vw;
              }
              p {
                font-size: 3vw;
              }
            }
          }
          &_cards2 {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 2vw;
            margin-bottom: 3vw;
            &_card {
              width: 80%;
              padding: 3vw 2vw 6vw 2vw;
              display: flex;
              align-items: center;
              flex-direction: column;
              &_icon {
                margin-bottom: 1vw;
                img {
                  width: 10vw;
                }
              }
              h4 {
                font-size: 4vw;
                line-height: 5vw;
                margin-bottom: 1vw;
              }
              p {
                font-size: 3vw;
              }
            }
          }
        }
      }
    }
    &_bottom {
      &_image {
        padding: 10vw 0;
        background-image: url(../../assets/images/bottomp4.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        &_text {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          h3 {
            font-size: 4vw;
            line-height: 5vw;
            margin-bottom: 3vw;
          }
          p {
            font-size: 3vw;
            font-weight: 500;
            line-height: 4vw;
            width: 90%;
            text-align: justify;
          }
        }
      }
    }
    &_request {
      &_section {
        width: 90%;
        border-radius: 1vw;
        display: flex;
        flex-direction: column-reverse;
        margin: 3vw 0;
        padding: 3vw;
        &_left {
          width: 100%;
          align-items: center;
          h3 {
            font-size: 4vw;
            line-height: 5vw;
            margin-bottom: 3vw;
            text-align: justify;
          }
          p {
            font-size: 3vw;
            line-height: 4vw;
            margin-bottom: 4vw;
            text-align: justify;
          }
          button {
            width: 30vw;
            font-size: 3vw;
            padding: 1vw;
            border-radius: 0.8vw;
            margin-bottom: 2vw;
          }
        }
        &_right {
          width: 100%;
          img {
            width: 100%;
          }
        }
      }
    }
  }
}
