.header {
  &_container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
  }
  &_content {
    width: 100%;
    background-color: #07426a;
    &_right {
      display: flex;
      justify-content: flex-end;
      width: 90%;
      &_icons {
        display: flex;
        justify-content: space-between;
        width: 13%;
        padding: 1vw 0;
        img {
          width: 2vw;
          cursor: pointer;
        }
      }
    }
  }
  &_section {
    display: flex;
    justify-content: space-between;
    background-color: #fffdfd;
    width: 85%;
    &_left {
      width: 35%;
      padding: 0.5vw 0;
      img {
        width: 60%;
        cursor: pointer;
      }
    }
    &_right {
      display: flex;
      align-items: center;
      width: 45%;
      ul {
        display: flex;
        justify-content: space-between;
        list-style: none;
        width: 100%;
        li {
          font-size: 1.2vw;
          font-weight: 600;
        }
        .active {
          color: #f88e25 !important;
          // background-color: #f88e25;
        }
        .dropdown {
          position: relative;
          display: inline-block;
          &:hover .dropdown-content {
            display: block;
          }
        }
        .dropdown-content {
          display: none;
          position: absolute;
          background-color: #f9f9f9;
          min-width: 13vw;
          box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
          border-radius: 0.5vw;
          padding: 1vw;
          z-index: 1;
          a {
            color: #07426a;
            padding: 0.6vw;
            text-decoration: none;
            display: block;
            font-size: 1vw;
          }
        }
      }
    }
  }
  &_mobile {
    display: none;
  }
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    .header {
      &_container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        flex-direction: column;
      }
      &_content {
        width: 100%;
        background-color: #07426a;
        &_right {
          display: flex;
          justify-content: flex-end;
          width: 90%;
          &_icons {
            display: flex;
            justify-content: space-between;
            width: 28%;
            padding: 2vw 0;
            a{
              display: flex;
              align-items: center;
            }
            img {
              width: 5vw;
              cursor: pointer;
            }
          }
        }
      }
      &_section {
        display: none;
      }
      &_mobile {
        display: block;
        &_container {
          width: 100%;
          display: flex;
          justify-content: center;
          padding: 2vw 0;
        }
        &_section {
          position: relative;
          width: 90%;
          display: flex;
          justify-content: space-between;
          &_left {
            display: flex;
            align-items: center;
            img {
              width: 39%;
            }
          }
          &_right {
            display: flex;
            align-items: center;
          }
          &_content {
            box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23);
            background-color: #07426A;
            border-top: 1px solid #000;
            position: absolute;
            width: 100%;
            display: flex;
            top: 12.2vw;
            align-items: center;
            right: 0vw;
            display: flex;
            flex-direction: column;
            padding-bottom: 4vw;
            z-index: 1;
            ul {
              line-height: 7vw;
              li {
                text-align: center;
                font-size: 16px;
                font-weight: 500;
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
}
