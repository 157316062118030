.ed {
  width: 100%;
  &_banner {
    width: 100%;
    height: 50vh;
    &_heading {
      width: 32%;
      justify-content: center;
      display: flex;
      align-items: flex-end;
      height: 45vh;
      h1 {
        font-size: 2vw;
        font-weight: 500;
        color: #fff;
      }
    }
  }
  &_cont {
    width: 100%;
    display: flex;
    justify-content: center;
    &_sec {
      width: 85%;
      margin: 2vw 0;
      .custom-table {
        table {
          width: 100%;
          border-collapse: collapse;
          td {
            border: 1px solid #c3c3c3 !important;
            background-color: #FFF !important;
            padding: 5px;
          }
          th {
            background-color: #fff !important;
            font-weight: bold;
          }
        }
      }
    }
    
    
  }
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .ed {
    &_banner {
      width: 100%;
      height: 30vh;
      &_heading {
        height: 25vh;
        width: 35%;
        h1 {
          font-size: 5vw;
        }
      }
    }
  }
}
