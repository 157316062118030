@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.home {
  width: 100%;
  &_container {
    width: 100%;
    &_banner {
      width: 100%;
      height: 60vh;
      @include flexCenter;
      &_banner-section {
        width: 85%;
        &_banner-text {
          width: 55%;
          h1 {
            font-size: 2vw;
            font-weight: 500;
            color: #fff;
            span {
              color: #f88e25;
            }
          }
          p {
            font-size: 1.2vw;
            font-weight: 400;
            margin: 1vw 0 1.8vw 0;
            color: #fff;
            line-height: 2vw;
            span {
              color: #f88e25;
            }
          }
          button {
            width: 10vw;
            font-size: 1vw;
            font-weight: 500;
            background: linear-gradient(180deg, #ebc608 0%, #f88e25 91.2%);
            color: #fff;
            padding: 0.6vw;
            border: none;
            border-radius: 7px;
            cursor: pointer;
          }
          transform: translateX(100%);
          animation: slideFromRight 0.5s forwards;

          @keyframes slideFromRight {
            from {
              transform: translateX(100%);
            }
            to {
              transform: translateX(0);
            }
          }
        }
      }
    }
  }
  &_about {
    @include flexJCenter;
    width: 100%;
    background-color: #fff;
    margin-top: 3vw;
    &_container {
      @include flexJCenter;
      width: 85%;
    }
    &_section {
      @include flexColCenter;
      width: 100%;
      &_header {
        @include flexColCenter;
        margin-bottom: 3vw;
        h2 {
          font-size: 2vw;
          font-weight: 600;
          color: #07131b;
          transform-origin: center;
          animation: stretchAnimation 1s ease-in-out alternate;
        }
        img {
          width: 6vw;
        }
      }
      &_content {
        @include flexrowbetween;
        width: 100%;
        &_left {
          width: 45%;
          &_header {
            margin-bottom: 1vw;
            h3 {
              font-size: 1.6vw;
              font-weight: 800;
              color: #07131b;
              line-height: 2.5vw;
            }
          }
          &_data {
            p {
              font-size: 1.3vw;
              font-weight: 500;
              color: #545252;
              line-height: 2vw;
              text-align: justify;
              span {
                color: #f88e25;
              }
            }
          }
          &_button {
            margin-top: 1vw;
            button {
              width: 22%;
              font-size: 1vw;
              font-weight: 500;
              background: linear-gradient(180deg, #ebc608 0%, #f88e25 91.2%);
              color: #fff;
              padding: 0.6vw;
              border: none;
              border-radius: 7px;
              cursor: pointer;
            }
          }
        }
        &_right {
          width: 45%;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 90%;
          }
        }
      }
    }
  }
  &_product {
    &_container {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    &_header {
      display: flex;
      flex-direction: column;
      margin-bottom: 3vw;
      h2 {
        color: #07131b;
        text-align: center;
        font-size: 2vw;
        font-weight: 600;
        line-height: 2.5vw;
        transform-origin: center;
        animation: stretchAnimation 1s ease-in-out alternate;
      }
      &_line {
        display: flex;
        justify-content: flex-end;
        img {
          width: 6vw;
          margin-right: 1vw;
        }
      }
    }
    &_section {
      width: 90%;
      display: flex;
      align-items: center;
      flex-direction: column;
      position: relative;
      img {
        width: 70vw;
      }
      .bqms {
        position: absolute;
        cursor: pointer;
        width: 22vw;
        padding: 2.7vw;
        top: 0;
        right: 33.5vw;
        border-radius: 4vw;
        background-color: transparent;
        border: none;
      }
      .bqms1 {
        position: absolute;
        cursor: pointer;
        width: 14vw;
        padding: 7vw;
        bottom: 1vw;
        left: 10.5vw;
        border-radius: 20vw;
        background-color: transparent;
        border: none;
      }
      .bqms2 {
        position: absolute;
        cursor: pointer;
        width: 14vw;
        padding: 7vw;
        bottom: 1vw;
        right: 37.5vw;
        border-radius: 22vw;
        background-color: transparent;
        border: none;
      }
      .bqms3 {
        position: absolute;
        cursor: pointer;
        width: 14vw;
        padding: 7vw;
        bottom: 1vw;
        right: 10.5vw;
        border-radius: 22vw;
        background-color: transparent;
        border: none;
      }
    }
    &_content {
      width: 85%;
      display: flex;
      justify-content: space-around;
      margin: 3vw 0;
      &_qms {
        width: 28%;
        p {
          color: #463a3a;
          text-align: justify;
          font-size: 1vw;
          font-weight: 500;
          line-height: 2vw;
          span {
            cursor: pointer;
          }
        }
      }
      &_dms {
        width: 28%;
        p {
          color: #463a3a;
          text-align: justify;
          font-size: 1vw;
          font-weight: 500;
          line-height: 2vw;
          span {
            cursor: pointer;
          }
        }
      }
      &_tms {
        width: 28%;
        p {
          color: #463a3a;
          text-align: justify;
          font-size: 1vw;
          font-weight: 500;
          line-height: 2vw;
          span {
            cursor: pointer;
          }
        }
      }
    }
  }
  &_mp {
    display: none;
  }
  &_qms {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 3vw;
    background-color: #fff;
    &_body {
      width: 85%;
      &_container {
        &_heading {
          h3 {
            font-size: 2vw;
            text-align: center;
            transform-origin: center;
            animation: stretchAnimation 1s ease-in-out alternate;
          }

          &_image {
            text-align: end;
            width: 64%;
            img {
              width: 8vw;
              margin-bottom: 1vw;
            }
          }
        }
        &_section {
          display: flex;
          margin-top: 2vw;
          position: relative;
          overflow: hidden;
          &_left {
            width: 40%;
            &_content {
              width: 90%;
              height: max-content;
              &_heading {
                h2 {
                  font-size: 2vw;
                  font-weight: 600;
                  color: #07131b;
                }
              }
              &_text {
                height: 40vh;
                p {
                  font-size: 1.3vw;
                  font-weight: 500;
                  color: #545252;
                  line-height: 1.9vw;
                  text-align: justify;
                }
              }
              // &_button {
              //   margin-top: 2vw;
              //   h3 {
              //     width: 7vw;
              //     font-size: 1vw;
              //     font-weight: 700;
              //     color: #000;
              //     border: none;
              //   }
              //   &_border {
              //     border-bottom: 2px solid #f88e25;
              //     width: 5.5vw;
              //   }
              // }
            }
          }
          &_right {
            width: 60%;
            @include flexICenterJBetween;
            &_images {
              @include flexICenterJBetween;
              gap: 2.3vw;
              transition: transform 0.5s ease-in-out;
              img {
                width: 32%;
                height: 40vh;
                transition: opacity 0.5s ease-in-out;
                filter: grayscale(60%) brightness(60%);
                &.active {
                  filter: grayscale(20%) brightness(100%);
                }
              }
            }
          }
        }
        &_bottom {
          @include flexRowEnd;
          width: 100%;
          margin-top: 1vw;
          &_section {
            @include flexICeneterJEnd;
            width: 60%;
            &_arrows {
              @include flexRowAround;
              width: 10%;
              img {
                width: 2vw;
                cursor: pointer;
              }
            }
            &_line {
              width: 90%;
              @include flexJAround;
              border-bottom: 2px solid lightgray;
            }
          }
        }
      }
    }
  }
  &_client {
    width: 100%;
    @include flexJCenter;
    margin-top: 2vw;
    background-color: #ececec;
    &_body {
      width: 85%;
      &_clients {
        &_container {
          &_heading {
            margin: 3vw 0;
            h2 {
              font-size: 2vw;
              text-align: center;
              transform-origin: center;
              animation: stretchAnimation 1s ease-in-out alternate;
            }
            &_line {
              width: 57%;
              display: flex;
              justify-content: flex-end;
              img {
                width: 7vw;
              }
            }
          }
          &_section {
            margin-bottom: 4vw;
            .sliding-div {
              width: 100%;
              display: flex;
              overflow: hidden;
              position: relative;
              gap: 4vw;
              left: 0;
              transition: left 0.5s ease;
              &.stopped {
                left: -calc(4vw + 17vw);
              }
              img {
                width: 100%;
                height: auto;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .home {
    &_container {
      &_banner {
        width: 100%;
        height: 50vh;
        @include flexCenter;
        &_banner-section {
          width: 80%;
          &_banner-text {
            width: 100%;
            h1 {
              text-align: justify;
              font-size: 4vw;
            }
            p {
              font-size: 2.7vw;
              line-height: 4vw;
              text-align: justify;
            }
            button {
              width: 30%;
              font-size: 3vw;
              padding: 1vw 2vw;
            }
          }
        }
      }
    }
    &_about {
      @include flexJCenter;
      margin-top: 6vw;
      &_container {
        @include flexJCenter;
        width: 85%;
      }
      &_section {
        @include flexColCenter;
        width: 100%;
        &_header {
          @include flexColCenter;
          margin-bottom: 4vw;
          h2 {
            font-size: 5vw;
          }
          img {
            width: 12vw;
          }
        }
        &_content {
          flex-direction: column;
          width: 100%;
          &_left {
            width: 100%;
            &_header {
              margin-bottom: 2vw;
              h3 {
                font-size: 3.5vw;
                line-height: 5vw;
              }
            }
            &_data {
              p {
                font-size: 3.5vw;
                line-height: 4vw;
                text-align: justify;
              }
            }
            &_button {
              margin-top: 2vw;
              button {
                width: 30%;
                font-size: 3vw;
                padding: 1vw 2vw;
              }
            }
          }
          &_right {
            width: 100%;
            margin-top: 5vw;
            img {
              width: 100%;
            }
          }
        }
      }
    }
    &_product {
      display: none;
    }
    &_mp {
      display: block;
      &_container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 4vw 0;
        background: #edf2f4;
      }
      &_header {
        margin-bottom: 3vw;
        width: 50%;
        position: relative;
        img {
          width: 100%;
        }
        button {
          position: absolute;
          width: 100%;
          padding: 6vw;
          top: 0;
          left: 0;
          border-radius: 10vw;
          border: none;
          background: transparent;
        }
      }
      &_section {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 4vw;
        &_top {
          width: 30%;
          position: relative;
          img {
            width: 100%;
          }
          button {
            position: absolute;
            width: 100%;
            padding: 14vw;
            border-radius: 50%;
            left: 0;
            top: 0;
            border: none;
            background: transparent;
          }
        }
        &_bottom {
          width: 90%;
          p {
            font-size: 3.2vw;
            font-weight: 500;
            color: #463a3a;
            text-align: justify;
            line-height: 4vw;
          }
        }
      }
    }
    &_qms {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 3vw;
      background-color: #ffffff;
      &_body {
        width: 85%;
        &_container {
          &_heading {
            h3 {
              font-size: 5vw;
            }
            &_image {
              width: 80%;
              display: flex;
              justify-content: flex-end;
              img {
                width: 15vw;
                margin-bottom: 1vw;
              }
            }
          }
          &_section {
            display: flex;
            flex-direction: column;
            margin-top: 2vw;
            position: relative;
            overflow: hidden;
            &_left {
              width: 100%;
              &_content {
                width: 100%;
                height: 45vw;
                &_heading {
                  margin-bottom: 1vw;
                  h2 {
                    font-size: 4vw;
                  }
                }
                &_text {
                  p {
                    font-size: 3vw;
                    line-height: 4vw;
                    text-align: justify;
                  }
                }
                &_button {
                  margin-top: 2vw;
                  h3 {
                    width: 20vw;
                    font-size: 3vw;
                  }
                  &_border {
                    width: 17vw;
                  }
                }
              }
            }
            &_right {
              margin-top: 3vw;
              width: 100%;
              @include flexICenterJBetween;
              &_images {
                @include flexICenterJBetween;
                gap: 2.3vw;
                transition: transform 0.5s ease-in-out;
                img {
                  width: 100%;
                  height: 30vh;
                  transition: opacity 0.5s ease-in-out;
                  filter: grayscale(60%) brightness(60%);
                  &.active {
                    filter: grayscale(20%) brightness(100%);
                  }
                }
              }
            }
          }
          &_bottom {
            width: 100%;
            margin-top: 2vw;
            &_section {
              width: 60%;
              &_arrows {
                width: 30%;
                img {
                  width: 6vw;
                }
              }
              &_line {
                width: 80%;
              }
            }
          }
        }
      }
    }
    &_client {
      width: 100%;
      @include flexJCenter;
      margin-top: 4vw;
      background-color: #ececec;
      &_body {
        width: 90%;
        &_clients {
          &_container {
            &_heading {
              margin: 4vw 0;
              h2 {
                font-size: 5vw;
                text-align: center;
              }
              &_line {
                width: 65%;
                display: flex;
                justify-content: flex-end;
              }
              img{
                width: 12vw;
              }
            }
          }
        }
      }
    }
  }
}
