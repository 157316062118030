.privacy {
  display: flex;
  justify-content: center;
  &_container {
    width: 85%;
    padding: 2vw 0;
    display: flex;
    flex-direction: column;
    gap: 4vw;
  }
  &_header {
    text-align: center;
    h1 {
      font-size: 1.4vw;
      font-weight: 600;
    }
  }
  &_content {
    display: flex;
    flex-direction: column;
    gap: 2vw;
    &_sec {
      display: flex;
      flex-direction: column;
      gap: 0.4vw;
      h3 {
        font-size: 1vw;
        font-weight: 600;
        color: #545252;
      }
      h4 {
        font-size: 1vw;
        font-weight: 400;
        color: #545252;
      }
      h5 {
        font-size: 0.9vw;
        font-weight: 400;
        color: #545252;
      }
      ul {
        margin: 0 0 0 2vw;
        li {
          list-style-type: disc;
          font-size: 1vw;
          font-weight: 400;
          color: #545252;
        }
      }
    }
  }
}

@media screen and (max-width: 1090px) {
  .privacy {
    &_container {
      padding: 4vw 0;
      gap: 6vw;
    }
    &_header {
      h1 {
        font-size: 3vw;
      }
    }
    &_content {
      gap: 4vw;
      &_sec {
        gap: 1.2vw;
        h3 {
          font-size: 2.4vw;
        }
        h4 {
          font-size: 2.4vw;
        }
        h5 {
          font-size: 2vw;
        }
        ul {
          margin: 0 0 0 4vw;
          li {
            font-size: 2.4vw;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .privacy {
    &_container {
      width: 85%;
      padding: 4vw 0;
      gap: 6vw;
    }
    &_header {
      h1 {
        font-size: 5vw;
      }
    }
    &_content {
      gap: 6vw;
      &_sec {
        gap: 2vw;
        h3 {
          font-size: 3.4vw;
        }
        h4 {
          font-size: 3.4vw;
        }
        h5 {
          font-size: 3vw;
        }
        ul {
          margin: 0 0 0 6vw;
          li {
            font-size: 3.4vw;
          }
        }
      }
    }
  }
}
