.products {
  width: 100%;
  &_container {
    width: 100%;
    &_banner {
      width: 100%;
      height: 50vh;
      &_heading {
        width: 45%;
        justify-content: center;
        display: flex;
        align-items: flex-end;
        height: 45vh;
        h1 {
          font-size: 2vw;
          font-weight: 500;
          color: #fff;
        }
      }
    }
  }
  &_system {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #fff;
    margin-top: 3vw;
    &_container {
      display: flex;
      justify-content: center;
      width: 85%;
    }
    &_section {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      width: 100%;
      &_header {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 3vw;
        h2 {
          font-size: 2vw;
          font-weight: 600;
          color: #07131b;
          transform-origin: center;
          animation: stretchAnimation 1s ease-in-out alternate;
         
        }
        &_image {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          img {
            width: 6vw;
          }
        }
      }
      &_content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        &_left {
          width: 45%;
          &_header {
            margin-bottom: 1vw;
            h3 {
              font-size: 1.6vw;
              font-weight: 800;
              color: #07131b;
              line-height: 2vw;
            }
          }
          &_data {
            p {
              font-size: 1.3vw;
              font-weight: 500;
              color: #545252;
              line-height: 2vw;
              margin-bottom: 2vw;
              text-align: justify;
            }
          }
        }
        &_right {
          width: 45%;
          &_image {
            width: 98%;
            img {
              width: 100%;
            }
          }
        }
      }
    }
  }
  &_features {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #f0f0f0;
    margin-top: 3vw;
    &_container {
      display: flex;
      justify-content: center;
      width: 85%;
    }
    &_section {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      width: 100%;
      &_header {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 3vw;
        h2 {
          font-size: 2vw;
          font-weight: 600;
          color: #07131b;
          transform-origin: center;
          animation: stretchAnimation 1s ease-in-out alternate;
          
        }
        &_image {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          img {
            width: 6vw;
          }
        }
      }
      &_content {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        flex-wrap: wrap;
        &_cards {
          margin: 1vw;
          padding: 1vw;
          width: 20%;
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          &_icon {
            img {
              width: 5vw;
            }
          }
          &_text {
            margin: 0.5vw 0 1vw 0;
            h3 {
              color: #000;
              font-size: 1.3vw;
              font-weight: 700;
            }
          }
          &_content {
            p {
              color: rgba(0, 0, 0, 0.7);
              font-size: 1vw;
              font-weight: 500;
              line-height: 1.5vw;
            }
          }
        }
        .line {
          border-right: 1.7px solid darkgrey;
          height: 26vh;
          align-self: center;
        }
        &_down {
          display: flex;
          width: 80%;
        }
      }
    }
  }
  &_quality {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #fff;
    margin-top: 3vw;
    &_container {
      display: flex;
      justify-content: center;
      width: 85%;
    }
    &_section {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      width: 100%;
      &_header {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 3vw;
        h2 {
          font-size: 1.7vw;
          font-weight: 600;
          line-height: 2vw;
          transform-origin: center;
          animation: stretchAnimation 1s ease-in-out alternate;
          
        }
        &_image {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          img {
            width: 6vw;
          }
        }
      }
      &_content {
        display: flex;
        justify-content: space-between;
        &_left {
          width: 45%;
          &_class {
            border-radius: 10px;
            background: #fff;
            box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.25);
            padding: 1vw 1.5vw;
            margin-bottom: 2vw;
            &_icon {
              img {
                width: 3vw;
              }
            }
            &_text {
              &_head {
                padding: 1vw 0;
                h3 {
                  color: #000;
                  font-size: 1.1vw;
                  font-weight: 700;
                  line-height: 20px;
                }
              }
              &_content {
                display: flex;
                align-items: stretch;
                p {
                  color: #585555;
                  font-size: 1vw;
                  font-weight: 500;
                  line-height: 1.5vw;
                  text-align: justify;
                }
              }
            }
          }
        }
        &_right {
          width: 45%;
          &_class {
            border-radius: 10px;
            background: #fff;
            box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.25);
            padding: 1vw 1.5vw 2.5vw 1.5vw;
            margin-bottom: 2vw;
            &_icon {
              img {
                width: 3vw;
              }
            }
            &_text {
              &_head {
                padding: 1vw 0;
                h3 {
                  color: #000;
                  font-size: 1.1vw;
                  font-weight: 700;
                  line-height: 20px;
                }
              }
              &_content {
                display: flex;
                align-items: stretch;
                p {
                  color: #585555;
                  font-size: 1vw;
                  font-weight: 500;
                  line-height: 1.5vw;
                  text-align: justify;
                }
              }
            }
          }
        }
      }
    }
  }
  &_experience {
    &_container {
      margin-top: 2vw;
      &_banner {
        background-image: url(../../assets/images/qmsexperience.bg.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        display: flex;
        align-items: center;
        justify-content: center;
        height:65vh;
        &_sections {
          width: 100%;
          display: flex;
          justify-content: space-between;
          &_left {
            width: 35.5%;
            display: flex;
            justify-content: flex-end;
            padding-bottom: 13vw;
            &_section {
              padding: 2vw 0;
              border-radius: 10px;
              width: 88%;
              display: flex;
              justify-content: flex-start;
              flex-direction: column;
              align-items: flex-end;
              h3 {
                color: #FFFFFF;
                text-align: right;
                font-size: 1.2vw;
                font-weight: 700;
                margin-bottom: 1vw;
              }
              .line{
                border-bottom: 1px solid #8C8C8C;
                width: 76%;
                margin-bottom: 1vw;
              }
              p {
                color: #FFFFFF;
                text-align: right;
                font-size: 1.1vw;
                font-weight: 500;
                line-height: 1.5vw;
              }
            }
          }
          &_right {
            width: 36%;
            display: flex;
            align-items: center;
            padding-top: 13vw;
            padding-bottom: 1vw;
            &_section {
              padding: 1vw 0;
              border-radius: 10px;
              width: 100%;
              display: flex;
              justify-content: flex-end;
              flex-direction: column;
              align-items: flex-start;
              h3 {
                color: #FFFFFF;
                text-align: left;
                font-size: 1.2vw;
                font-weight: 700;
                margin-bottom: 1vw;
              }
              .line{
                border-bottom: 1px solid #8C8C8C;
                width: 76%;
                margin-bottom: 1vw;
              }
              p {
                color: #FFFFFF;
                text-align: left;
                font-size: 1.1vw;
                width: 90%;
                font-weight: 500;
                line-height: 1.5vw;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .products {
    &_container {
      &_banner {
        width: 100%;
        height: 30vh;
        &_heading {
          width: 90%;
          height: 25vh;
          h1 {
            font-size: 5vw;
          }
        }
      }
    }
    &_system {
      margin-top: 6vw;
      &_container {
        width: 85%;
      }
      &_section {
        &_header {
          h2 {
            font-size: 5vw;
          }
          &_image {
            width: 100%;
            img {
              width: 12vw;
            }
          }
        }
        &_content {
          flex-direction: column;
          width: 100%;
          &_left {
            width: 100%;
            &_header {
              margin-bottom: 1vw;
              h3 {
                font-size: 4vw;
                line-height: 5vw;
              }
            }
            &_data {
              p {
                text-align: justify;
                font-size: 3vw;
                line-height: 4vw;
                margin-bottom: 4vw;
              }
            }
          }
          &_right {
            width: 100%;
            margin-top: 3vw;
            &_image {
              width: 100%;
              img {
                width: 100%;
              }
            }
          }
        }
      }
    }
    &_features {
      width: 100%;
      &_container {
        width: 100%;
      }
      &_section {
        &_header {
          h2 {
            font-size: 5vw;
          }
          &_image {
            width: 100%;
            img {
              width: 15vw;
            }
          }
        }
        &_content {
          justify-content: space-around;
          align-items: flex-start;
          width: 100%;
          flex-wrap: wrap;
          padding-bottom: 3vw;
          &_cards {
            width: 100%;
            &_icon {
              img {
                width: 8vw;
              }
            }
            &_text {
              margin: 1vw 0 1vw 0;
              h3 {
                font-size: 4vw;
              }
            }
            &_content {
              width: 90%;
              p {
                // text-align: justify;
                font-size: 3vw;
                line-height: 4vw;
              }
            }
          }
          .line {
            display: none;
          }
          &_down {
            display: flex;
            width: 80%;
          }
        }
      }
    }
    &_quality {
      &_section {
        &_header {
          h2 {
            font-size: 5vw;
            line-height: 6vw;
          }
          &_image {
            img {
              width: 15vw;
            }
          }
        }
        &_content {
          display: flex;
          flex-direction: column;
          &_left {
            width: 100%;
            &_class {
              display: flex;
              &_icon {
                img {
                  width: 10vw;
                  margin-top: 0.5vw;
                }
              }
              &_text {
                padding: 0 3vw;
                &_head {
                  h3 {
                    font-size: 4vw;
                    line-height: 5vw;
                  }
                }
                &_content {
                  p {
                    font-size: 3vw;
                    line-height: 4vw;
                    margin-bottom: 4vw;
                    margin-top: 1vw;
                    width: 100%;
                    text-align: justify;
                  }
                }
              }
            }
          }
          &_right {
            width: 100%;
            &_class {
              display: flex;
              &_icon {
                img {
                  width: 10vw;
                  margin-top: 0.5vw;
                }
              }
              &_text {
                padding: 0 3vw;
                &_head {
                  h3 {
                    font-size: 4vw;
                    line-height: 5vw;
                  }
                }
                &_content {
                  p {
                    font-size: 3vw;
                    line-height: 4vw;
                    margin-bottom: 4vw;
                    margin-top: 1vw;
                    width: 100%;
                    text-align: justify;
                  }
                  &:first-child {
                    p{
                      margin-bottom: 5vw;
                    }
                }
                }
              }
            }
          }
        }
      }
    }
    &_experience {
      &_container {
        margin-top: 6vw;
        &_banner {
          background-image: url(../../assets/images/QMSmobileBg.jpg);
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          height: fit-content;
          display: flex;
          align-items: center;
          justify-content: center;
          &_sections {
            width: 95%;
            margin-top: 1vw;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding-bottom: 4vw;
            &_left {
              width: 100%;
              align-items: center;
              padding-bottom: 0;
              &_section {
                width: 100%;
                h3 {
                  font-size: 4vw;
                }
                p {
                  font-size: 3vw;
                  line-height: 4vw;
                }
              }
            }
            &_right {
              margin: 0;
              align-items: center;
              width: 100%;
              padding-bottom: 0;
              padding-top: 2vw;
              &_section {
                width: 99%;
                h3 {
                  font-size: 4vw;
                }
                p {
                  font-size: 3vw;
                  line-height: 4vw;
                }
              }
            }
          }
        }
      }
    }
  }
}
