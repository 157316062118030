@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap");

h1,
h2,
h3,
h4,
h5,
h6{
  margin: 0;
  font-family: "Inter", sans-serif;
}

p, button, input, select {
  margin: 0;
  font-family: 'Outfit', sans-serif;
}

ul {
  padding: 0;
  margin: 0;
  li {
    list-style-type: none;
    font-family: 'Outfit', sans-serif;
  }
}

@keyframes stretchAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
