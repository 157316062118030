@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.about-us {
  &_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;

    &_banner {
      width: 100%;
      height: 50vh;
      &_banner-text {
        width: 24%;
        justify-content: center;
        display: flex;
        align-items: flex-end;
        height: 45vh;
        h1 {
          font-size: 2vw;
          font-weight: 500;
          color: #fff;
          text-align: justify;
        }
      }
    }
    &_aboutUs-section {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      // &_heading {
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;
      //   flex-direction: column;
      //   width: 45%;
      //   padding: 3vw 0;
      //   h1 {
      //     font-size: 1.8vw;
      //     font-weight: 600;
      //   }
      //   img {
      //     width: 15%;
      //   }
      // }
      &_content {
        display: flex;
        width: 85%;
        flex-direction: column;
        margin-top: 3vw;
        &_section1 {
          display: flex;
          justify-content: space-between;
          align-items: center;
          &_left {
            width: 46%;
            p {
              font-size: 1.2vw;
              font-weight: 500;
              line-height: 2vw;
              margin-bottom: 1.5vw;
              color: #545252;
              text-align: justify;
            }
            .astakeins-text1 {
              color: #f48f18;
            }
          }
          &_right {
            display: flex;
            justify-content: flex-end;
            width: 48%;
            .image1 {
              width: 95%;
              img {
                width: 90%;
                border-radius: 1.8vw 0 1.8vw 0;
              }
            }
          }
        }
        &_section2 {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 2.8vw;
          &_left {
            display: flex;
            justify-content: flex-start;
            width: 45%;
            .image2 {
              width: 100%;
              img {
                width: 100%;
              }
            }
          }
          &_right {
            width: 50%;
            p {
              font-size: 1.2vw;
              font-weight: 500;
              line-height: 2vw;
              margin-bottom: 1.5vw;
              color: #545252;
              text-align: justify;
            }
          }
        }
      }
    }
    &_QMS-section {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 3vw;
      background-color: #d4ecff;
      &_content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 85%;
        position: relative;
        &_heading {
          display: flex;
          align-items: flex-end;
          flex-direction: column;
          width: 25%;
          padding: 3vw 0 3vw 0;
          h1 {
            font-size: 1.8vw;
            font-weight: 600;
            transform-origin: center;
            animation: stretchAnimation 1s ease-in-out alternate;
          }
          img {
            width: 40%;
          }
        }
        &_card-section {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          width: 100%;
          &_card:nth-child(3n) {
            margin: 0 0 3.6vw 0;
          }
          &_card:last-child {
            margin: 0 0 3.6vw 3vw;
          }
          &_card:hover {
            box-shadow: 1px 3px 5px 0px rgba(0, 0, 0, 0.25);
            background-color: #8cb2e7;
            background-image: linear-gradient(#8cb2e7, #43679b);
            color: #fff;
            .image1 {
              display: block;
            }
            .image2 {
              display: block;
            }
          }
          &_card {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 25%;
            padding: 1.7vw 1.7vw;
            background-color: #fff;
            border-radius: 20px;
            margin: 0 3.6vw 3.6vw 0;
            position: relative;

            .image1 {
              position: absolute;
              top: -0.5vw;
              left: -0.6vw;
              width: 30%;
              display: none;
              img {
                width: 58%;
              }
            }
            .card-logo {
              width: 28%;
              img {
                width: 100%;
              }
            }
            .card-text {
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              margin-top: 1.5vw;
              h2 {
                font-size: 1.2vw;
                font-size: 600;
              }
              p {
                font-size: 1vw;
                font-weight: 500;
                text-align: center;
                margin-top: 1vw;
                line-height: 1.6vw;
              }
            }
            .image2 {
              position: absolute;
              right: -3.8vw;
              bottom: -0.8vw;
              width: 30%;
              display: none;
              img {
                width: 58%;
              }
            }
          }
        }
      }
    }
    &_banner-astakenis {
      width: 100%;
      &_banner-image {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding-top: 3vw;
        &_banner-content {
          display: flex;
          align-items: center;
          flex-direction: column;
          width: 90%;
          padding-bottom: 10vw;
          h1 {
            font-size: 2vw;
            font-weight: 600;
            color: #fff;
            padding: 2vw;
            span {
              color: #f48f18;
            }
          }
          p {
            font-size: 1.5vw;
            font-weight: 600;
            text-align: center;
            line-height: 2.5vw;
            color: #fff;
          }
          button {
            width: 8vw;
            font-size: 1vw;
            font-weight: 500;
            margin-top: 2vw;
            background: linear-gradient(180deg, #ebc608 0%, #f88e25 91.2%);
            color: #fff;
            padding: 0.6vw;
            border: none;
            border-radius: 5px;
            cursor: pointer;
          }
        }
      }
    }
    &_banner-joinUs {
      width: 82%;
      display: flex;
      justify-content: center;
      position: relative;
      bottom: 6vw;
      &_banner-image {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 3vw;
        border-radius: 1vw;
        background-color: #efefef;
        &_banner-content {
          display: flex;
          align-items: center;
          flex-direction: column;
          width: 90%;
          h1 {
            font-size: 2vw;
            font-weight: 600;
            color: #383535;
          }
          p {
            font-size: 1.5vw;
            font-weight: 600;
            text-align: center;
            color: #383535;
            padding: 2vw 0;
            line-height: 2.5vw;
          }
          button {
            width: 8vw;
            font-size: 1.2vw;
            font-weight: 500;
            margin-top: 1vw;
            background: linear-gradient(180deg, #ebc608 0%, #f88e25 91.2%);
            color: #fff;
            padding: 0.6vw;
            border: none;
            border-radius: 5px;
            cursor: pointer;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .about-us {
    &_container {
      &_banner {
        height: 30vh;
        &_banner-text {
          height: 20vh;
          padding-top: 9vw;
          width: 35%;
          h1 {
            font-size: 5vw;
          }
        }
      }
      &_aboutUs-section {
        // &_heading {
        //   width: 45%;
        //   padding: 4vw;
        //   h1 {
        //     font-size: 5vw;
        //     font-weight: 600;
        //   }
        //   img {
        //     width: 15vw;
        //   }
        // }
        &_content {
          &_section1 {
            display: flex;
            flex-direction: column;
            margin: 3vw 0;
            &_left {
              width: 100%;
              p {
                text-align: justify;
                font-size: 3.5vw;
                line-height: 4.5vw;
                padding-bottom: 3vw;
              }
              .astakeins-text1 {
                color: #f48f18;
              }
            }
            &_right {
              width: 100%;
              margin-top: 3vw;
              .image1 {
                width: 95%;
                img {
                  width: 100%;
                  border-radius: 1.8vw 0 1.8vw 0;
                }
              }
            }
          }
          &_section2 {
            display: flex;
            flex-direction: column-reverse;
            margin-top: 2.8vw;
            &_left {
              width: 100%;
              .image2 {
                width: 100%;
                img {
                  width: 100%;
                }
              }
            }
            &_right {
              width: 100%;
              p {
                font-size: 3.5vw;
                line-height: 4.5vw;
                text-align: justify;
                padding-bottom: 3vw;
              }
            }
          }
        }
      }
      &_QMS-section {
        &_content {
          width: 85%;
          margin-bottom: 5vw;
          &_heading {
            display: flex;
            align-items: center;
            flex-direction: column;
            width: 100%;
            padding: 3.5vw 0 4vw 0;
            h1 {
              font-size: 5vw;
              font-weight: 600;
            }
            img {
              width: 15vw;
            }
          }
          &_card-section {
            flex-direction: column;
            width: 100%;
            &_card:nth-child(3n) {
              margin: 0;
              margin-bottom: 3vw;
            }
            &_card:last-child {
              margin: 0;
            }
            &_card:hover {
              box-shadow: 1px 3px 5px 0px rgba(0, 0, 0, 0.25);
              background-color: #8cb2e7;
              background-image: linear-gradient(#8cb2e7, #43679b);
              color: #fff;
              .image1 {
                display: none;
              }
              .image2 {
                display: none;
              }
            }
            &_card {
              align-items: center;
              flex-direction: column;
              width: 90%;
              padding: 5vw;
              margin: 0;
              margin-bottom: 3vw;
              .image1 {
                width: 30%;
                display: none;
                img {
                  width: 10vw;
                }
              }
              .card-logo {
                width: 20%;
                img {
                  width: 100%;
                }
              }
              .card-text {
                h2 {
                  font-size: 4vw;
                }
                p {
                  font-size: 3.5vw;
                  line-height: 4.5vw;
                }
              }
              .image2 {
                width: 30%;
                display: none;
                img {
                  width: 10vw;
                }
              }
            }
          }
        }
      }
      &_banner-astakenis {
        width: 100%;
        &_banner-image {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          &_banner-content {
            display: flex;
            align-items: center;
            flex-direction: column;
            width: 90%;
            padding-bottom: 10vw;
            h1 {
              font-size: 4vw;
              font-weight: 600;
              padding: 2vw;
              span {
                color: #f48f18;
              }
            }

            p {
              font-size: 3vw;
              font-weight: 400;
              text-align: justify;
              line-height: 4vw;
            }
            button {
              width: 8vw;
              font-size: 1vw;
              font-weight: 500;
              margin-top: 2vw;
              background: linear-gradient(180deg, #ebc608 0%, #f88e25 91.2%);
              color: #fff;
              padding: 0.6vw;
              border: none;
              border-radius: 5px;
              cursor: pointer;
            }
          }
        }
      }
      &_banner-joinUs {
        width: 90%;
        &_banner-image {
          &_banner-content {
            width: 95%;
            h1 {
              font-size: 4vw;
              padding: 2vw;
            }
            p {
              font-size: 3.5vw;
              text-align: justify;
              line-height: 4.5vw;
            }
            button {
              width: 15vw;
              font-size: 3vw;
              padding: 0.6vw;
            }
          }
        }
      }
    }
  }
}
