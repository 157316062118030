// get color names from here https://chir.ag/projects/name-that-color/#6195ED

// colors

$primary_white: #ffffff;
$primary_black: #000000;
$primary_blue: #263238;
$primary_grey: #ededed;
$white_shadow: #ffffff90;
$grey_input: #a8adaf;
$secondary_grey: #d4d6d7;
$font_grey: #677074;
$font_grey1: #757575;
$grey_button: #d9d9d9;
$bg_light_grey: #fbfbfb;
$bg_black_shadow: #00000020;
$bg_grey_backround: #F8F8F9;

// font family names
$inter_black: "Inter Black";
$inter_bold: "Inter Bold";
$inter_extrabold: "Inter ExtraBold";
$inter_italic: "Inter Italic";
$inter_light: "Inter Light";
$inter_medium: "Inter Medium";
$inter_regular: "Inter Regular";
$inter_semibold: "Inter SemiBold";
$inter_thin: "Inter Thin";

// font family path
$inter_black_file: "../assets/fonts/Inter-Black.ttf";
$inter_bold_file: "../assets/fonts/Inter-Bold.ttf";
$inter_extrabold_file: "../assets/fonts/Inter-ExtraBold.ttf";
// $inter_italic_file: "../assets/fonts/Inter-Italic.ttf";
$inter_light_file: "../assets/fonts/Inter-Light.ttf";
$inter_medium_file: "../assets/fonts/Inter-Medium.ttf";
$inter_regular_file: "../assets/fonts/Inter-Regular.ttf";
$inter_semibold_file: "../assets/fonts/Inter-SemiBold.ttf";
$inter_thin_file: "../assets/fonts/Inter-Thin.ttf";

//Font-siz2
$font_top1: 20vw;
$font_top2: 16vw;
$font_top3: 14vw;
$font_top4: 12vw;
$font_top5: 10vw;
$font_top6: 8vw;
$font_top7: 6vw;
$font_top8: 5vw;
$font_top9: 4vw;
$font_top10: 3vw;
$font_xxlarge: 2.4vw;
$font_xlarge: 2vw;
$font_large: 1.8vw;
$font_regular: 1.6vw;
$font_button: 1.5vw;
$font_medium: 1.4vw;
$font_text: 1.3vw;
$font_small: 1.2vw;
$font_xxsmall: 1.1vw;
$font_xsmall: 1vw;
$font_verysmall: 0.9vw;
$font_xverysmall: 0.5vw;
