@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.pt {
  width: 100%;
  &_banner {
    width: 100%;
    height: 50vh;
    &_text {
      width: 49%;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      height: 45vh;
      h2 {
        font-size: 2vw;
        font-weight: 500;
        color: #fff;
      }
    }
  }
  &_dms {
    @include flexJCenter;
    width: 100%;
    background-color: #fff;
    margin-top: 3vw;
    &_container {
      @include flexJCenter;
      width: 85%;
    }
    &_section {
      @include flexColCenter;
      width: 100%;
      &_header {
        @include flexColCenter;
        margin-bottom: 3vw;
        width: 40%;
        h3 {
          font-size: 2vw;
          font-weight: 600;
          color: #07131b;
          transform-origin: center;
          animation: stretchAnimation 1s ease-in-out alternate;
          
        }
      }
      &_line {
        display: flex;
        justify-content: flex-end;
        width: 90%;
        img {
          width: 6vw;
        }
      }
      &_content {
        @include flexrowbetween;
        width: 100%;
        &_left {
          width: 50%;
          img {
            width: 85%;
          }
        }
        &_right {
          width: 50%;
          &_heading {
            margin-bottom: 1vw;
            h3 {
              font-size: 1.6vw;
              font-weight: 800;
              color: #000000;
              line-height: 3vw;
            }
          }
          &_data {
            margin-bottom: 2vw;
            p {
              font-size: 1.3vw;
              font-weight: 500;
              color: #545252;
              line-height: 2.4vw;
              text-align: justify;
            }
          }
        }
      }
    }
  }
  &_features {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #f0f0f0;
    margin-top: 3vw;
    &_container {
      display: flex;
      justify-content: center;
      width: 85%;
      padding: 3vw 0;
    }
    &_section {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      width: 100%;
      &_header {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        h2 {
          font-size: 2vw;
          font-weight: 600;
          color: #07131b;
          transform-origin: center;
          animation: stretchAnimation 1s ease-in-out alternate;
          
        }
        &_image {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          img {
            width: 8vw;
          }
        }
      }
      &_content {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        width: 96%;
        flex-wrap: wrap;
        &_cards {
          margin: 1vw;
          padding: 1vw;
          width: 26%;
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          &_icon {
            img {
              width: 5vw;
            }
          }
          &_text {
            margin: 0.5vw 0 1vw 0;
            h3 {
              color: #000;
              font-size: 1.3vw;
              font-weight: 700;
            }
          }
          &_content {
            p {
              color: rgba(0, 0, 0, 0.7);
              font-size: 1vw;
              font-weight: 500;
              line-height: 1.5vw;
            }
          }
        }
        .line {
          border-right: 1.7px solid darkgrey;
          height: 26vh;
          align-self: center;
        }
        &_down {
          display: flex;
          width: 80%;
        }
      }
    }
  }
  &_why {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #fff;
    margin: 3vw 0;
    &_container {
      display: flex;
      justify-content: center;
      width: 100%;
      position: relative;
    }
    &_section {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      width: 90%;
      &_header {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 3vw;
        h2 {
          font-size: 1.7vw;
          font-weight: 600;
          line-height: 2vw;
          transform-origin: center;
          animation: stretchAnimation 1s ease-in-out alternate;
          
        }
        &_image {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          img {
            width: 8vw;
          }
        }
      }
      &_content {
        display: flex;
        padding: 1vw;
        gap: 2vw;
        &_left {
          &_class {
            border-radius: 15px;
            background: #fff;
            box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.2);
            width: 25%;
            &_icon {
              img {
                width: 4vw;
                position: relative;
                bottom: 2vw;
                left: 8vw;
              }
            }
            &_text {
              padding: 0 1vw;
              &_head {
                h3 {
                  color: #000;
                  font-size: 1.1vw;
                  font-weight: 700;
                  line-height: 20px;
                  text-align: center;
                }
              }
              &_content {
                p {
                  color: #585555;
                  font-size: 1vw;
                  font-weight: 500;
                  line-height: 1.4vw;
                  margin-bottom: 1vw;
                  margin-top: 0.5vw;
                  // width: 90%;
                  letter-spacing: 0.1px;
                  text-align: center;
                }
              }
            }
          }
        }
      }
    }
  }
  &_start {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 3vw;
    &_container {
      width: 85%;
      background-image: url(../../assets/images/getstartedbg.png);
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 2vw;
      padding: 2vw 0 2vw 7vw;
    }
    &_section {
      width: 90%;
      display: flex;
      flex-direction: column;
      &_heading {
        margin-top: 1vw;
        h3 {
          text-align: center;
          margin-bottom: 2vw;
          font-size: 2.5vw;
          font-weight: 700;
        }
      }
      &_content {
        width: 64%;
        margin-bottom: 1vw;
        p {
          text-align: justify;
          font-size: 1.4vw;
          font-weight: 500;
          margin-bottom: 1vw;
        }
      }
      &_button {
        button {
          background: linear-gradient(180deg, #ebc608 0%, #f88e25 91.2%);
          border: none;
          color: #fff;
          padding: 1vw 2vw;
          font-size: 1vw;
          width: 11vw;
          border-radius: 7px;
          cursor: pointer;
        }
      }
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .pt {
    &_banner {
      width: 100%;
      height: 30vh;
      &_text {
        width: 92%;
        height: 25vh;
        h2 {
          font-size: 5vw;
        }
      }
    }
    &_dms {
      &_container{
        width: 90%;
      }
      &_section {
        width: 100%;
        &_header {
          width: 100%;
          margin-bottom: 0;
          h3 {
            font-size: 5vw;
          }
        }
        &_line {
          img {
            width: 12vw;
          }
        }
        &_content {
          flex-direction: column-reverse;
          width: 100%;
          &_left {
            width: 100%;
            img {
              width: 100%;
            }
          }
          &_right {
            width: 100%;
            margin-top: 3vw;
            &_heading {
              margin-bottom: 1vw;
              h3 {
                font-size: 4vw;
                line-height: 5vw;
              }
            }
            &_data {
              margin-bottom: 2vw;
              p {
                text-align: justify;
                font-size: 3.5vw;
                line-height: 4.5vw;
                padding-bottom: 4vw;
              }
            }
          }
        }
      }
    }
    &_features {
      width: 100%;
      &_container {
        width: 100%;
      }
      &_section {
        &_header {
          h2 {
            font-size: 5vw;
          }
          &_image {
            width: 100%;
            img {
              width: 15vw;
            }
          }
        }
        &_content {
          justify-content: space-around;
          align-items: flex-start;
          width: 100%;
          flex-wrap: wrap;
          padding-bottom: 3vw;
          &_cards {
            width: 100%;
            &_icon {
              img {
                width: 8vw;
              }
            }
            &_text {
              margin: 1vw 0 1vw 0;
              h3 {
                font-size: 4vw;
              }
            }
            &_content {
              width: 90%;
              p {
                font-size: 3vw;
                line-height: 4vw;
                // text-align: justify;
              }
            }
          }
          .line {
            display: none;
          }
          &_down {
            display: flex;
            width: 80%;
          }
        }
      }
    }
    &_why {
      &_section {
        width: 100%;
        &_header {
          margin-bottom: 5vw;
          h2 {
            font-size: 5vw;
          }
          &_image {
            img {
              width: 11vw;
            }
          }
        }
        &_content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 8vw;
          &_left {
            &_class {
              width: 100%;
              &_icon {
                img {
                  width: 10vw;
                  bottom: 4.5vw;
                  left: 42.5vw;
                }
              }
              &_text {
                padding: 0 3vw 3vw 3vw;
                &_head {
                  h3 {
                    font-size: 4vw;
                    line-height: 5vw;
                    text-align: center;
                    margin-bottom: 2vw;
                  }
                }
                &_content {
                  p {
                    font-size: 3vw;
                    line-height: 4vw;
                    text-align: justify;
                  }
                }
              }
            }
          }
        }
      }
    }
    &_start {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 3vw;
      &_container {
        width: 95%;
        background: linear-gradient(
          180deg,
          rgba(237, 232, 232, 0.81) 0%,
          rgba(181, 181, 181, 0.4) 100%
        );
        border-radius: 2vw;
        padding: 2vw 3vw;
        display: flex;
        justify-content: center;
      }
      &_section {
        width: 100%;
        display: flex;
        flex-direction: column;
        &_heading {
          margin-top: 3vw;
          h3 {
            margin-bottom: 4vw;
            font-size: 4vw;
          }
        }
        &_content {
          width: 100%;
          margin-bottom: 1vw;
          p {
            text-align: justify;
            font-size: 3vw;
            font-weight: 500;
            margin-bottom: 3vw;
          }
        }
        &_button {
          display: flex;
          justify-content: center;
          margin-bottom: 3vw;
          button {
            background: linear-gradient(180deg, #ebc608 0%, #f88e25 91.2%);
            border: none;
            color: #fff;
            padding: 1vw 2vw;
            font-size: 2.5vw;
            width: 20vw;
            border-radius: 1vw;
            cursor: pointer;
          }
        }
      }
    }
  }
}
