@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.footer {
  &_container {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    background-color: #07426a;
    &_section {
      width: 95%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      &_section1 {
        margin-top: 1vw;
        &_list {
          ul {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1.3vw;
            li {
              a {
                font-size: 1.2vw;
                font-weight: 550;
                color: #f1f1f1;
                text-decoration: none;
              }
            }
          }
        }
      }
      &_section2 {
        display: flex;
        flex-direction: column;
        &_heading {
          h3 {
            color: #f1f1f1;
            font-size: 1.3vw;
            font-weight: 600;
            line-height: 4vw;
          }
        }
        &_content {
          p {
            color: #f1f1f1;
            font-size: 0.9vw;
            font-weight: 550;
            line-height: 1.8vw;
          }
          span {
            text-decoration: underline;
          }
          a{
            color: #f1f1f1;
            font-size: 0.9vw;
            font-weight: 550;
            line-height: 1.8vw;
            text-decoration: underline;
          }
        }
      }
      &_section3 {
        display: flex;
        flex-direction: column;
        margin-top: 1.5vw;
        &_heading {
          h3 {
            color: #f1f1f1;
            font-size: 1.3vw;
            font-weight: 600;
            line-height: 4vw;
          }
        }
        &_content {
          p {
            color: #f1f1f1;
            font-size: 0.9vw;
            font-weight: 550;
            line-height: 1.8vw;
          }
          span {
            text-decoration: underline;
          }
          a{
            color: #f1f1f1;
            font-size: 0.9vw;
            font-weight: 550;
            line-height: 1.8vw;
            text-decoration: underline;
          }
        }
      }
    }
    &_logos {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 90%;
      border-top: 1px solid white;
      margin-top: 2vw;
      &_images {
        padding: 1vw 0;
        display: flex;
        gap: 1vw;
        img {
          width: 2.5vw;
        }
      }
      p {
        color: #f1f1f1;
        font-size: 1vw;
        font-weight: 550;
        span {
          color: #f1f1f1;
          font-size: 0.8vw;
          font-weight: 500;
        }
      }
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .footer {
    &_container {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 100%;
      background-color: #07426a;
      padding-top: 2vw;
      &_section {
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        &_section1 {
          margin-top: 1.5vw;
          &_list {
            ul {
              display: flex;
              flex-direction: row;
              li {
                a {
                  font-size: 3vw;
                  font-weight: 500;
                }
              }
            }
          }
        }
        &_section2 {
          display: flex;
          flex-direction: column;
          margin-top: 4vw;
          &_heading {
            margin-bottom: 1vw;
            h3 {
              color: #f1f1f1;
              font-size: 4vw;
              font-weight: 500;
            }
          }
          &_content {
            p {
              font-size: 3vw;
              line-height: 4vw;
              font-weight: 400;
            }
            span {
              font-size: 3vw;
              line-height: 4vw;
              font-weight: 400;
            }
            a{
              font-size: 3vw;
              line-height: 4vw;
              font-weight: 400;
            }
          }
        }
        &_section3 {
          display: flex;
          flex-direction: column;
          margin-top: 4vw;
          &_heading {
            margin-bottom: 1vw;
            h3 {
              font-size: 4vw;
              line-height: 5vw;
              font-weight: 500;
            }
          }
          &_content {
            p {
              font-size: 3vw;
              line-height: 4vw;
              font-weight: 400;
            }
            span {
              text-decoration: underline;
            }
            a{
              font-size: 3vw;
              line-height: 4vw;
              font-weight: 400;
              color: #FFF;
            }
          }
        }
      }
      &_logos {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 90%;
        border-top: 1px solid white;
        margin-top: 4vw;
        padding-bottom: 1vw;
        &_images {
          margin-top: 1.5vw;
          display: flex;
          gap: 1vw;
          img {
            width: 5vw;
          }
        }
        p {
          font-size: 2.5vw;
          font-weight: 500;
          span {
            color: #f1f1f1;
            font-size: 2.5vw;
            font-weight: 500;
          }
        }
      }
    }
  }
}
