@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.event {
  width: 100%;
  &_banner {
    width: 100%;
    height: 50vh;
    &_heading {
      width: 21.5%;
      justify-content: center;
      display: flex;
      align-items: flex-end;
      height: 45vh;
      h1 {
        font-size: 2vw;
        font-weight: 500;
        color: #fff;
      }
    }
  }
  &_cont {
    width: 100%;
    display: flex;
    justify-content: center;
    &_sec {
      width: 92%;
      margin: 2vw 0;
      padding: 10px;
      &_heading {
        @include flexColCenter;
        margin-bottom: 3vw;
        h2 {
          font-size: 2vw;
          font-weight: 600;
          color: #07131b;
          transform-origin: center;
          animation: stretchAnimation 1s ease-in-out alternate;
        }
        img {
          width: 8vw;
        }
      }
      &_search {
        width: 100%;
        position: relative;
        .search {
          position: absolute;
          top: 14px;
          left: 20px;
        }
        input {
          width: 95%;
          padding: 10px 10px 10px 45px;
          border-radius: 6px;
          font-size: 14px;
          border: 2px solid #838383;
        }
      }
      &_body {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 2vw 0;
        &_left {
          width: 8%;
          &_date {
            display: flex;
            flex-direction: column;
            align-items: center;
            &_month {
              span {
                font-size: 1.2vw;
                color: #656565;
                font-weight: 500;
                text-transform: uppercase;
                padding: 0;
                margin: 0;
              }
            }
            &_day {
              span {
                font-size: 2vw;
                color: #07131b;
                font-weight: 700;
                padding: 0;
                margin: 0;
              }
            }
            &_year {
              span {
                font-size: 1.2vw;
                color: #656565;
                font-weight: 500;
                padding: 0;
                margin: 0;
              }
            }
          }
        }
        &_right {
          width: 60%;
          &_details {
            width: 100%;
            &_time {
              margin-bottom: 1vw;
              span {
                font-size: 1.2vw;
                color: #07131b;
                font-weight: 400;
                padding: 0;
                margin: 0;
              }
            }
            &_title {
              margin-bottom: 0.8vw;
              h2 {
                font-size: 1.8vw;
                color: #07131b;
                font-weight: 600;
                cursor: pointer;
              }
              h2:hover {
                text-decoration: underline;
              }
            }
            &_content {
              width: 100%;
              display: flex;
              justify-content: space-between;
              &_text {
                width: 100%;
                p {
                  font-weight: 400;
                  font-size: 1.2vw;
                  color: #656565;
                  line-height: 2vw;
                }
              }
            }
          }
        }
        &_image {
          width: 30%;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          img {
            width: 100%;
            height: 200px;
            object-fit: contain;
          }
        }
      }
    }
  }

  .loaderWrapper {
    @include flexCenter;
    background: #00000062;
    overflow: hidden;
    z-index: 100;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100vh;

    &__loader {
      @include absoluteCenter;
      border: 4px solid #f0f0f0;
      border-radius: 50%;
      border-top: 4px solid #212121;
      width: $font_top9;
      height: $font_top9;
      -webkit-animation: spin 2s linear ease-in-out;
      animation: spin 2s linear ease-in-out;
    }

    &__smallLoader {
      border: 2px solid #f0f0f0;
      border-top: 2px solid #212121;
      width: $font_large;
      height: $font_large;
    }
    &__tableLoader {
      border: 2px solid #f0f0f0;
      border-top: 2px solid #212121;
      width: $font_large;
      height: $font_large;
    }

    @-webkit-keyframes spin {
      0% {
        -webkit-transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
      }
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}

// @media screen and (min-width: 320px) and (max-width: 768px) {
//   .event {
//     &_banner {
//       width: 100%;
//       height: 30vh;
//       &_heading {
//         height: 25vh;
//         width: 35%;
//         h1 {
//           font-size: 5vw;
//         }
//       }
//     }
//     &_cont {
//       width: 100%;
//       &_sec {
//         &_heading {
//           h2 {
//             font-size: 5vw;
//           }
//         }
//         &_search{
//           input{
//             width: 88%;
//           }
//         }
//         &_body {
//           width: 100%;
//           flex-direction: column;
//           align-items: center;
//           &_left {
//             width: 100%;
//             &_date {
//               flex-direction: row;
//               justify-content: center;
//               gap: 5px;
//               &_month {
//                 span {
//                   font-size: 3vw;
//                   font-weight: 500;
//                 }
//               }
//               &_day {
//                 span {
//                   font-size: 3vw;
//                   font-weight: 700;
//                 }
//               }
//               &_year {
//                 span {
//                   font-size: 3vw;
//                   font-weight: 500;
//                 }
//               }
//             }
//           }
//           &_right {
//             width: 100%;
//             &_details {
//               width: 100%;
//               &_time {
//                 margin-bottom: 1vw;
//                 display: flex;
//                 justify-content: space-between;
//                 span{
//                   font-size: 3vw;
//                 }
//                 &_left {
//                   span {
//                     font-size: 3vw;
//                     font-weight: 400;
//                   }
//                 }
//               }
//               &_title {
//                 margin-bottom: 1vw;
//                 h2 {
//                   font-size: 4vw;
//                   font-weight: 600;
//                 }
//               }
//               &_content {
//                 width: 100%;
//                 flex-direction: column;
//                 &_text {
//                   width: 100%;
//                   p {
//                     font-size: 3.2vw;
//                     line-height: 4vw;
//                   }
//                 }
//               }
//             }
//           }
//           &_image{
//             width: 100%;
//             margin: 10px 0;
//           }
//         }
//       }
//     }
//   }
// }

@media screen and (max-width: 768px) {
  .event {
    &_banner {
      height: 30vh;
      &_heading {
        width: 21.5%;
        height: 25vh;
        h1 {
          font-size: 4vw;
        }
      }
    }
    &_cont {
      &_sec {
        width: 92%;
        margin: 2vw 0;
        padding: 10px;
        &_heading {
          @include flexColCenter;
          margin-bottom: 3vw;
          h2 {
            font-size: 4vw;
          }
          img {
            width: 12vw;
          }
        }
        &_search {
          input {
            width: 90%;
          }
        }
        &_body {
          padding: 4vw 0;
          &_left {
            width: 10%;
            &_date {
              display: flex;
              flex-direction: column;
              align-items: center;
              &_month {
                span {
                  font-size: 14px;
                }
              }
              &_day {
                span {
                  font-size: 18px;
                }
              }
              &_year {
                span {
                  font-size: 14px;
                }
              }
            }
          }
          &_right {
            width: 85%;
            &_details {
              width: 100%;
              &_time {
                margin-bottom: 1.5vw;
                span {
                  font-size: 14px;
                }
              }
              &_title {
                margin-bottom: 0.8vw;
                width: 100%;
                h2 {
                  font-size: 20px;
                }
                h2:hover {
                  text-decoration: underline;
                }
              }
              &_content {
                display: none;
              }
            }
          }
          &_image {
            display: none;
          }
        }
      }
    }
  }
}
